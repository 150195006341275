$(document).ready(function () {
    $(".file-tree").filetree();
    fileTree();

    $('#tree').overlayScrollbars({ });
    $('.terminal-console').overlayScrollbars({ });
    $('.error-layout').overlayScrollbars({ });

    //$('.clsNotifications').parent().parent().find('.lm_header').addClass('clsNotificationHeader');

    $('.clsNotifications').closest('.lm_stack').addClass('clsRightLayout');
});
