function goldenLayout() {

// GOLDEN-LAYOUT
    var config = {
        settings: {
            constrainDragToContainer: true,
            reorderEnabled: true,
            selectionEnabled: false,
            popoutWholeStack: false,
            blockedPopoutsThrowError: true,
            closePopoutsOnUnload: true,
            showPopoutIcon: false,
            showMaximiseIcon: false,
            showCloseIcon: true,

        },
        dimensions: {
            headerHeight: 30
        },
        content: [{
            type: 'row',
            content: [{
                type: 'stack',
                width: 15,
                id: 'conTree',
                content: [{
                    type: 'component',
                    componentName: 'treeListComponent',
                    title: 'EXPLORER',
                    header: {
                        show: false
                    },

                    isClosable: false,
                    isMaximised: false,
                }]
            }
                , {

                    type: 'column',
                    width: 100,
                    content: [{
                        type: 'row',
                        height: 70,
                        content: [{
                            width: 70,
                            type: 'component',
                            title: 'Title',
                            header: {
                                show: false
                            },
                            id: 'conMain',
                            show: false,
                            componentName: 'editorComponent',
                            isClosable: false,
                            isMaximised: false,
                        }, {
                            type: 'stack',
                            width: 20,
                            isClosable: false,
                            isMaximised: true,
                            id: 'sidebar',
                            content: [
                                {
                                    width: 10,
                                    type: 'component',
                                    title: 'NOTIFICATIONS',
                                    id: 'componentNotifications',
                                    show: false,
                                    componentName: 'componentNotifications',
                                    isClosable: true,
                                    isMaximised: false,
                                }]
                        }]
                    },
                        {
                            type: 'row',
                            content: [{
                                type: 'stack',
                                width: 100,
                                isClosable: false,
                                isMaximised: true,
                                id: 'lFooter',
                                content: [
                                    {
                                        type: 'component',
                                        title: 'TERMINAL',
                                        id: 'componentTerminal',
                                        componentName: 'componentTerminal'
                                    },
                                    {
                                        type: 'component',
                                        title: 'PROBLEMS',
                                        componentName: 'componentProblems',
                                        id: 'componentProblems'
                                    },
                                    {
                                        type: 'component',
                                        title: 'DEBUG CONSOLE',
                                        isClosable: true,
                                        componentName: 'componentConsole',
                                        id: 'componentConsole'
                                    }
                                ]
                            }]
                        }]
                }]
        }]
    };

    var myLayout = new GoldenLayout(config);
    myLayout.registerComponent('treeListComponent', function (container) {
        container.getElement().html(`<div class="row tree-top">
    <div class="col-6">
        <div class="title">EXPLORER</div>
    </div>
    <div class="col-6 text-right icons float-right pl-0">
        <a href="#"><span class="icon-locate n-7 mt-1 d-inline-block"></span></a>
        <a href="#"><span class="icon-more-v n-7 mt-1 d-inline-block"></span></a>
    </div>
</div>
<div data-simplebar data-simplebar-auto-hide="false" id="tree">
     <ul class="file-tree">
    <li id="1" class="open"><a href="#"><span>src/main/java</span></a>
        <ul>
            <li id="2" class="open"><a href="#"><span>de.yatta.platform.eclipse.profiles.web</span></a>
                <ul>
                    <li id="3"><a href="#"><span>admin</span></a>
                        <ul>
                            <li id="4"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="5"><a href="#"><span>auth</span></a>
                        <ul>
                            <li id="6"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="7"><a href="#"><span>component</span></a>
                        <ul>
                            <li id="7"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="9"><a href="#"><span>config</span></a>
                        <ul>
                            <li id="10"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="11"><a href="#"><span>ehub</span></a>
                        <ul>
                            <li id="12"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="13"><a href="#"><span>legal</span></a>
                        <ul>
                            <li id="14"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="15"><a href="#"><span>profile</span></a>
                        <ul>
                            <li id="16"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="17"><a href="#"><span>resources</span></a>
                        <ul>
                            <li id="18"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="19"><a href="#"><span>status</span></a>
                        <ul>
                            <li id="20"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="21"><a href="#"><span>util</span></a>
                        <ul>
                            <li id="22"><a href="#"><span>test</span></a></li>
                        </ul>
                    </li>
                    <li id="23"><a href="#"><span>Changelog.java</span></a></li>
                    <li id="24"><a href="#"><span>EclipseProfileModel.java</span></a></li>
                    <li id="25"><a href="#"><span>EclipseProfilesApplication.java</span></a></li>
                    <li id="26"><a href="#"><span>EclipseProfilesApplicationUrlRender.java</span></a></li>
                    <li id="27"><a href="#"><span>EclipseProfilesApplicationUrlRequestCycleProvider.java</span></a></li>
                    <li id="28" class="current"><a href="#"><span>EclipseProfilesPage.java</span></a></li>
                    <li id="29"><a href="#"><span>EclipseProfilesSession.java</span></a></li>
                    <li id="30"><a href="#"><span>EclipseWelcomePage.java</span></a></li>
                    <li id="31"><a href="#"><span>Help.java</span></a></li>
                    <li id="32"><a href="#"><span>LandingPage.java</span></a></li>
                    <li id="33"><a href="#"><span>LandingPageEnterpriseCloud.java</span></a></li>
                    <li id="34"><a href="#"><span>LandingPageEnterpriseCloudCampaign.java</span></a></li>
                    <li id="35"><a href="#"><span>ProfilesNavigation.java</span></a></li>
                    <li id="36"><a href="#"><span>RedirectToLandingPage.java</span></a></li>
                    <li id="37"><a href="#"><span>SpinnerPage.java</span></a></li>
                    <li id="38"><a href="#"><span>EclipseProfilesApplication.properties.xml</span></a></li>
                </ul>
            </li>
            <li id="39"><a href="#"><span>src/main/resources</span></a>
                <ul>
                    <li id="40"><a href="#"><span>test</span></a></li>
                </ul>
            </li>
            <li id="41"><a href="#"><span>src/main/webapp/WEB-INF</span></a>
                <ul>
                    <li id="42"><a href="#"><span>test</span></a></li>
                </ul>
            </li>
            <li id="43"><a href="#"><span>src/test/java</span></a>
                <ul>
                    <li id="44"><a href="#"><span>test</span></a></li>
                </ul>
            </li>
            <li id="45"><a href="#"><span>Maven Dependencies</span></a>
                <ul>
                    <li id="46"><a href="#"><span>test</span></a></li>
                </ul>
            </li>
            <li id="47"><a href="#"><span>JRE System Library [JavaSE-1.8]</span></a>
                <ul>
                    <li id="48"><a href="#"><span>test</span></a></li>
                </ul>
            </li>
            <li id="49"><a href="#"><span>.settings</span></a>
                <ul>
                    <li id="50"><a href="#"><span>test</span></a></li>
                </ul>
            </li>
            <li id="51"><a href="#"><span>src</span></a>
                <ul>
                    <li id="52"><a href="#"><span>test</span></a></li>
                </ul>
            </li>
            <li id="53"><a href="#"><span>target</span></a>
                <ul>
                    <li id="54"><a href="#"><span>test</span></a></li>
                </ul>
            </li>
            <li id="55"><a href="#"><span>.classpath</span></a></li>
            <li id="56"><a href="#"><span>.igonore</span></a></li>
            <li id="57"><a href="#"><span>.project</span></a></li>
            <li id="58"><a href="#"><span>.pom.xml</span></a></li>
            <li id="59"><a href="#"><span>profiles server(8013).launch</span></a></li>
        </ul>
    </li>
</ul>

</div>
`);
        container.on('resize', function () {
            $('#footer-left').width(container.width);
        });


    });

    myLayout.registerComponent('editorComponent', function (container, componentState) {
        container.getElement().html(`<div id="main">
    <div class="row">
        <div class="col-12 px-0">
            <ul class="nav nav-tabs" role="tablist">
                <li class="active" data-tab="15"><a href="#tab1" data-toggle="tab" role="tab">
                    <div class="icon-document d-inline f-14"></div>
                    EclipseProfilesPage.java</a><span><div
                        class="icon-small-cross n-6 mt-2"></div></span>
                </li>

                <li class="new-tab"><a href="#" class="add-new-tab">
                    <div class="icon-plus n-6"></div>
                </a>
                </li>
            </ul>
            
        </div>
 
    </div>
    <div class="row">
        <div class="col-12 breadcrumb-top">
            src/main/java<span class="icon-chevron-right"></span>de.yatta.platform.eclipse.profiles.web<span class="icon-chevron-right"></span>EclipseProfilesPage.java
        </div>
    </div>

    <div class="tab-content ml-1" id="main-panel">
        <div class="tab-pane active" id="tab1">
        </div>
    </div>
    <div id="footer-editor">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 text-right" style="display: none">
                    <span>Loream</span>
                    <span>Ipsum</span>
                    <span>UTF-8</span>
                </div>
            </div>
        </div>
    </div>
</div>`);
        var con = 0;
        container.on('resize', function () {
            if (con > 0) {
                updateContainer();
            }
            con++;
        });
    });

    myLayout.registerComponent('componentProblems', function (container, componentState) {
        container.getElement().html(`<div class="px-2 component error-layout">
<div class="row ">
    <div class="col-5 th">Description</div>
    <div class="col-2 pl-2 th">Resources</div>
    <div class="col-1 pl-2 th">Path</div>
    <div class="col-2 pl-2 th">Location</div>
    <div class="col-2 pl-2 th">Type</div>
</div>
<div data-toggle="collapse" data-target="#accordion" class="row clickable collapse-row">
    <div class="col-5"><span class="total error">2</span><span>Errors</span></div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div id="accordion" class="collapse show">
    <div class="row even">
        <div class="col-5"><span class="ico-error icon-small-cross"></span> Panel 12 cannot be resolved into a type</div>
        <div class="col-2"></div>
        <div class="col-1"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
    </div>
    <div class="row">
        <div class="col-5"><span class="ico-error icon-small-cross"></span> Platform 124 Url cannot be resolved into a variable</div>
        <div class="col-2"></div>
        <div class="col-1"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
    </div>
</div>
<div data-toggle="collapse" data-target="#accordion2" class="row even clickable collapse-row collapsed">
    <div class="col-5"><span class="total warning">2</span><span>Warnings</span></div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div id="accordion2" class="collapse">
    <div class="row">
        <div class="col-5"><span class="ico-warning icon-warning-sign y-7"></span>Loream Ipsum Dolorem</div>
        <div class="col-2"></div>
        <div class="col-1"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
    </div>
    <div class="row even">
        <div class="col-5"><span class="ico-warning icon-warning-sign y-7"></span>Ut enim ad minim veniam, quis nostrud exercitation</div>
        <div class="col-2"></div>
        <div class="col-1"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
    </div>
</div>
<div data-toggle="collapse" data-target="#accordion3" class="row clickable collapse-row collapsed">
    <div class="col-5"><span class="total information">3</span><span>Information</span></div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div id="accordion3" class="collapse">
    <div class="row even">
        <div class="col-5"><span class="icon-info icon-info-sign b-7"></span> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
        <div class="col-2"></div>
        <div class="col-1"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
    </div>
    <div class="row">
        <div class="col-5"><span class="icon-info icon-info-sign b-7"></span> Ut enim ad minim veniam, quis nostrud exercitation</div>
        <div class="col-2"></div>
        <div class="col-1"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
    </div>
      <div class="row even">
        <div class="col-5"><span class="icon-info icon-info-sign b-7"></span> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
        <div class="col-2"></div>
        <div class="col-1"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
    </div>
</div>
<div class="row even">
    <div class="col-5"> </div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div class="row">
    <div class="col-5"> </div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div class="row even">
    <div class="col-5"> </div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div class="row">
    <div class="col-5"> </div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div class="row even">
    <div class="col-5"> </div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div class="row">
    <div class="col-5"> </div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div class="row even">
    <div class="col-5"> </div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
<div class="row even">
    <div class="col-5"> </div>
    <div class="col-2"></div>
    <div class="col-1"></div>
    <div class="col-2"></div>
    <div class="col-2"></div>
</div>
</div>`);
    });

    myLayout.on('itemDestroyed', function (tab) {
            if ($('.clsRightLayout .lm_header .lm_tabs').children().length == '1') {
                tab.parent.config.width = 1;
                myLayout.updateSize();
            }
    });

    myLayout.on('itemCreated', function (tab) {
        if (tab.componentName == 'componentNotifications') {
            tab.parent.config.width = 25;
            myLayout.updateSize();

        }
    });


    myLayout.registerComponent('componentConsole', function (container, componentState) {
        container.getElement().html('<div class="px-2 component debug-console">Debugging...</div>');
    });

    myLayout.registerComponent('componentNotifications', function (container, componentState) {
        container.getElement().html('<div class="px-2 component">A place for notifications...</div>');
        container.getElement().parent().addClass('clsNotifications');
    });

    myLayout.registerComponent('componentTerminal', function (container, componentState) {
        container.getElement().html(`<div class="px-2 component terminal-console">Terminal
[10:52:53] Starting 'scripts'...<br />
[Browsersync] 2 files changed (app.js.map, app.js)<br />
[10:52:53] Finished 'scripts' after 17 ms<br />
[Browsersync] Reloading Browsers... (buffered 2 events)<br />
</div>
`);
    });

    myLayout.init();


    var addMenuItem = function (title, tabTitle, component, container, stack) {
        var element = $('<li>' + title + '</li>');
        $('#' + container).append(element);

        var newItemConfig = {
            title: tabTitle,
            type: 'component',
            componentName: component,
            id: component // componentTerminal
        };

        //check if component exits(is opened), if not, open it.
        element.click(function () {
            if (myLayout.root.getItemsById(component)['0']) {
            } else {
                myLayout.root.getItemsById(stack)['0'].addChild(newItemConfig);
            }
        });
    };

    addMenuItem('<span class="icon-warning-sign n-8"></span> 1 ', 'PROBLEMS', 'componentProblems', 'menuContainer', 'lFooter');
    addMenuItem('<span class="icon-console n-8"></span> Terminal', 'TERMINAL', 'componentTerminal', 'menuContainer', 'lFooter');

    addMenuItem('<a href="#"><span class="icon-notifications n-7"></span></a>', 'NOTIFICATIONS', 'componentNotifications', 'menuContainerToolbar', 'sidebar');


}

goldenLayout();

