$(window).on('load', function () {

    $(".nav-tabs").on("click", "a", function (e) {
        e.preventDefault();
        if (!$(this).hasClass('add-new-tab')) {

            $(this).tab('show');
            //$('.breadcrumb-top').html = '';
            //$('.breadcrumb-top').html(breadCrumb($(this).parent().data('tab')));

            if ($(this).parent().data('tab') > 1) {
                $('.breadcrumb-top').html('');
                $('.breadcrumb-top').html(breadCrumb($('#' + $(this).parent().data('tab'))));
            } else {
                $('.breadcrumb-top').html('');
            }


            $('.nav-tabs li').removeClass('active');
            $(this).parent().addClass('active');
        }
    })

    //close tab
        .on("click", "span", function () {

            var anchor = $(this).siblings('a');
            $(anchor.attr('href')).remove();
            $(this).parent().remove();
            var index = tabs.indexOf('' + $(this).parent().data('tab') + ''); // get index if value found otherwise -1

            if (index > -1) { //if found
                tabs.splice(index, 1);
            }
            $(".nav-tabs li").children('a').first().click();
        });

    $('.add-new-tab').click(function (e) {
        var editor = 'tab' + id;

        e.preventDefault();
        var id = $(".nav-tabs").children().length;
        var tabId = 'tab' + id;

        $(this).closest('li').before('<li><a href="#tab' + id + '"><div class="icon-document d-inline f-14"></div> untitled</a> <span> <div class="icon-small-cross n-6 mt-2"></div> </span></li>');
        $('.tab-content').append('<div class="tab-pane" id="' + tabId + '"></div>');
        $('.nav-tabs li:nth-child(' + id + ') a').click();

        require(['vs/editor/editor.main'], function () {

            window[tabId] = monaco.editor.create(document.getElementById(tabId), {
                value: [
                    '// untitled',
                    ''
                ].join('\n'),
                language: 'java',
                minimap: {
                    enabled: false
                }

            });
        });
    });


    require.config({paths: {'vs': 'assets/js/vs'}});
    require(['vs/editor/editor.main'], function () {
        var tabId = 'tab1';
        window[tabId] = monaco.editor.create(document.getElementById('tab1'), {
            value: [

`package de.yatta.platform.eclipse.profiles.web;

import java.net.URL;
import java.util.Objects;

import javax.servlet.http.Cookie;

import org.apache.wicket.AttributeModifier;
import org.apache.wicket.markup.head.IHeaderResponse;
import org.apache.wicket.markup.head.JavaScriptHeaderItem;
import org.apache.wicket.markup.head.OnDomReadyHeaderItem;
import org.apache.wicket.markup.html.WebMarkupContainer;
import org.apache.wicket.markup.html.basic.Label;
import org.apache.wicket.markup.html.link.AbstractLink;
import org.apache.wicket.markup.html.link.BookmarkablePageLink;
import org.apache.wicket.model.IModel;
import org.apache.wicket.model.Model;
import org.apache.wicket.model.ResourceModel;
import org.apache.wicket.request.Request;
import org.apache.wicket.request.Url;
import org.apache.wicket.request.cycle.RequestCycle;
import org.apache.wicket.request.mapper.parameter.PageParameters;
import org.apache.wicket.util.cookies.CookieUtils;
import org.joda.time.DateTime;
import org.joda.time.format.ISODateTimeFormat;

import com.google.common.base.Strings;

import de.yatta.platform.eclipse.profiles.web.resources.js.EclipseProfilesPageJSResourceReference;
import de.yatta.platform.eclipse.profiles.web.util.DownloadLauncherTrackingUrl;
import de.yatta.platform.web.PlatformPage3;
import de.yatta.platform.web.PlatformSession;
import de.yatta.platform.web.legal.TermsAndPrivacyDownloadPanel.DownloadTrackingUrl;

public class EclipseProfilesPage extends PlatformPage3
{
   protected static final String COOKIE_KEY_VISITED_FIRST = "Profiles-Visited-First";
   private transient CookieUtils cookieUtils;

   protected static enum SocialMediaType
   {
      TWITTER, FACEBOOK
   }

   public EclipseProfilesPage()
   {
      super();
   }

   public EclipseProfilesPage(PageParameters parameters)
   {
      super(parameters);
   }

   @Override
   protected void onInitialize()
   {
      super.onInitialize();

      configureMetaTags();
      saveFirstTimeVisited();
   }

   @Override
   protected void onConfigure()
   {
      super.onConfigure();

      cookieUtils = getCookieUtils();
   }

   @Override
   protected void onBeforeRender()
   {
      if (PlatformSession.get().isSignedIn() && PlatformSession.get().getUser() != null)
      {
         boolean userAcceptetRecentYattaTerms = userService.hasUserAcceptetRecentYattaTerms(PlatformSession.get().getUser().getId());
         getTermsAndPrivacyConfirmationPanel().setVisibilityAllowed(!userAcceptetRecentYattaTerms);
      }

      super.onBeforeRender();
   }

   @Override
   protected DownloadTrackingUrl createDownloadTrackingUrl()
   {
      return DownloadLauncherTrackingUrl.createDefault();
   }

   @Override
   public void renderHead(IHeaderResponse response)
   {
      super.renderHead(response);

      response.render(JavaScriptHeaderItem.forReference(EclipseProfilesPageJSResourceReference.get()));
      easing(response);
   }

   protected void configureMetaTags()
   {
      IModel<String> titleModel = getMetaTagTitleModel(null);

      configureMetaTagContent("ogTitle", titleModel);
      configureMetaTagContent("ogDescription", getMetaTagDescriptionModel(SocialMediaType.FACEBOOK));
      configureMetaTagContent("ogUrl", getMetaTagUrlModel());
      configureMetaTagContent("ogImage", getMetaTagImageModel(SocialMediaType.FACEBOOK));
      configureMetaTagContent("twitterTitle", titleModel);
      configureMetaTagContent("twitterDescription", getMetaTagDescriptionModel(SocialMediaType.TWITTER));
   }

   private Model<String> getMetaTagUrlModel()
   {
      return Model.of(RequestCycle.get().getUrlRenderer().renderFullUrl(
            Url.parse(urlFor(getClass(), getPageParameters()).toString())));
   }

   protected IModel<String> getMetaTagTitleModel(SocialMediaType type)
   {
      return new ResourceModel("ogTitle.default");
   }

   protected IModel<String> getMetaTagDescriptionModel(SocialMediaType type)
   {
      switch (type)
      {
         case FACEBOOK:
            return new ResourceModel("ogDescription.default");
         case TWITTER:
            return new ResourceModel("twitterDescription.default");
         default:
            return new ResourceModel("ogDescription.default");
      }
   }

   protected IModel<String> getMetaTagImageModel(SocialMediaType type)
   {
      return new ResourceModel("ogImage.default");
   }

   private void configureMetaTagContent(String metaTagId, IModel<String> contentModel)
   {
      WebMarkupContainer metaTagContainer = new WebMarkupContainer(metaTagId);
      metaTagContainer.add(new AttributeModifier("content", contentModel));
      metaTagContainer.setVisibilityAllowed(!Strings.isNullOrEmpty(contentModel.getObject()));

      add(metaTagContainer);
   }

   protected void easing(IHeaderResponse response)
   {
      response.render(OnDomReadyHeaderItem.forScript("initEasing(" + getHeaderCssOffset() + ")"));
   }

   protected int getHeaderCssOffset()
   {
      return 110;
   }

   protected AbstractLink createSupportLink()
   {
      AbstractLink supportLink = new BookmarkablePageLink<Void>(ProfilesNavigation.CALL_TO_ACTION_LINK_ID, Help.class);
      supportLink.add(new Label(ProfilesNavigation.CALL_TO_ACTION_LABEL_ID, new ResourceModel("profiles.support.callToAction")));
      return supportLink;
   }

   @Override
   public ProfilesNavigation getNavigation()
   {
      return (ProfilesNavigation)super.getNavigation();
   }

   /**
    * @return the {@link DateTime} this browser visited a profiles page for the first time. May return null if the user
    * never visited
    * a profiles page before.
    */
   protected DateTime getFirstTimeVisited()
   {
      Cookie lastVisitedCookie = getCookieUtils().getCookie(COOKIE_KEY_VISITED_FIRST);
      if (lastVisitedCookie != null)
      {
         return ISODateTimeFormat.dateTimeParser().parseDateTime(lastVisitedCookie.getValue());
      }
      return null;
   }

   protected void saveFirstTimeVisited()
   {
      if (getFirstTimeVisited() == null)
      {
         getCookieUtils().save(COOKIE_KEY_VISITED_FIRST, ISODateTimeFormat.dateTime().print(new DateTime()));
      }
   }

   protected boolean isEclipseProfilesUrl(URL urlToCheck)
   {
      Request request = RequestCycle.get().getRequest();
      Url requestedUrl = request.getOriginalUrl();
      return Objects.equals(requestedUrl.getHost(), urlToCheck.getHost()) //
            && (Objects.equals(requestedUrl.getPort(), urlToCheck.getDefaultPort()) ||
                  Objects.equals(requestedUrl.getPort(), urlToCheck.getPort()));
   }

   protected CookieUtils getCookieUtils()
   {
      if (cookieUtils == null)
      {
         cookieUtils = new CookieUtils();
      }
      return cookieUtils;
   }
}`,
            ].join('\n'),
            language: 'java',
            minimap: {
                enabled: false
            }

        });

        fetch('/assets/js/vs/themes/Yatta.json')
            .then(data => data.json())
            .then(data => {
                monaco.editor.defineTheme('yatta', data);
                monaco.editor.setTheme('yatta');
            });
    });
});
