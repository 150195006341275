(function ($) {

    var clicked = false;
    var tabs = ['15']; //current tab


    $.fn.filetree = function (method) {

        var settings = { // settings to expose
            animationSpeed: 'fast',
            collapsed: true,
            console: false
        }
        var methods = {
            init: function (options) {
                // Get standard settings and merge with passed in values
                var options = $.extend(settings, options);
                // Do this for every file tree found in the document
                return this.each(function () {

                    var $fileList = $(this);

                    $fileList
                        .addClass('tree-list')
                        .find('li')
                        .has('ul') // Any li that has a list inside is a folder root
                        .addClass('folder-root closed')
                        .on('click', 'a[href="#"]', function (e) { // Add a click override for the folder root links
                            e.preventDefault();
                            $(this).parent().toggleClass('closed').toggleClass('open');

                            return false;
                        });
                    $('li.open').removeClass('closed');

                });
            }
        }

        if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.on("error", function () {
                console.log(method + " does not exist in the file explorer plugin");
            });
        }
    }


}(jQuery));

var clicked = false;
var tabs = ['28']; //current tab



function fileTree() {
    $.fn.filetree = function (method) {

        var settings = { // settings to expose
            animationSpeed: 'fast',
            collapsed: true,
            console: false
        }
        var methods = {
            init: function (options) {
                // Get standard settings and merge with passed in values
                var options = $.extend(settings, options);
                // Do this for every file tree found in the document
                return this.each(function () {

                    var $fileList = $(this);

                    $fileList
                        .addClass('tree-list')
                        .find('li')
                        .has('ul') // Any li that has a list inside is a folder root
                        .addClass('folder-root closed')
                        .on('click', 'a[href="#"]', function (e) { // Add a click override for the folder root links
                            e.preventDefault();
                            $(this).parent().toggleClass('closed').toggleClass('open');

                            return false;
                        });
                    $('li.open').removeClass('closed');

                });
            }
        }

        if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.on("error", function () {
                console.log(method + " does not exist in the file explorer plugin");
            });
        }
    }


    $('.file-tree li').click(function (e) {
        if (!$(this).hasClass('folder-root')) {
            if (!clicked) {
                if (tabs.includes($(this).attr('id'))) {
                    $('.tree-list li.current').removeClass('current');
                    $(this).addClass('current');
                    $('.nav-tabs').find("[data-tab='" + $(this).attr('id') + "']").children('a').first().click();

                } else {
                    e.preventDefault();

                    var editor = 'tab' + id;
                    var id = $(".nav-tabs").children().length;
                    var tabId = 'tab' + id;

                    $('.tree-list li.current').removeClass('current');
                    $(this).addClass('current');

                    $('.nav-tabs').find('li.new-tab').before('<li data-tab="' + $(this).attr('id') + '"><a href="#tab' + id + '"><div class="icon-document d-inline f-14"></div> ' + $(this).text() + '</a> <span> <div class="icon-small-cross n-6 mt-2"></div> </span></li>');
                    $('.tab-content').append('<div class="tab-pane" id="' + tabId + '"></div>');
                    $('.nav-tabs li:nth-child(' + id + ') a').click();

                    require(['vs/editor/editor.main'], function () {

                        window[tabId] = monaco.editor.create(document.getElementById(tabId), {
                            value: [
                                '// untitled',
                                ''
                            ].join('\n'),
                            language: 'javascript',
                            minimap: {
                                enabled: false
                            }

                        });
                    });

                    clicked = true;
                    setTimeout(function () {
                        clicked = false;
                    }, 300);

                    tabs.push($(this).attr('id'));
                }
            }

            updateContainer();
        }
    });
};

function breadCrumb(currentID) {
    var path = [];
    var el = currentID;

    var id = '';

    do {
        id = el.attr("id");
        current = document.getElementById(id).childNodes[0].textContent;

        path.unshift(current);
        el = el.parent().closest('li');
    }
    while (el.length != 0);

    return path.join('<span class="icon-chevron-right"></span>')
}
