$("#search").click(function(){
   var currentEditor = $("ul.nav-tabs li.active a").attr('href').replace('#', '');
    //console.log(currentEditor);

    var currentEditorStr = "window."+currentEditor+".getAction('actions.find').run()";

    var currentEditorExec = new Function (currentEditorStr);

    return(currentEditorExec());
});


$(window).resize(function(){
    updateContainer();
});

function updateContainer() {
    var currentEditor = $("ul.nav-tabs li.active a").attr('href').replace('#', '');
    var currentEditorStr = "window."+currentEditor+".layout();";
    var currentEditorExec = new Function (currentEditorStr);
    return(currentEditorExec());
}
